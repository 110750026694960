import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";
import BanerMDD from "../../components/BanerMDD/BanerMDD";

const KW51 = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #c8123d, #e3093c, #f22b59, #e3093c, #c8123d )",
        icons: "#c8123d",
        navClass: "kw51",
      }}
      seo={{
        title: "KW51",
        headerTitle: "kw51",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/kw51_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/kw51/",
      }}
    >
      <PortfolioHeader name="kw51" height="276" />
      <section className="container-fluid kw51_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>
                Kazimierza <br /> Wielkiego 51
              </h1>
              <ul>
                <li>Logo</li>
                <li>Strona internetowa</li>
                <li>Materiały marketingowe</li>
              </ul>
            </div>
          </div>
          <div className="col-md-5 offset-md-1">
            <div className="inner">
              <p>
                Deweloper miał już stworzoną stronę internetową przez inną firmę
                i planował zmienić agencję która odpowiadała za generowanie
                leadów sprzedażowych. Wykonaliśmy audyt kampanii z którego
                wynikało, że kampania była źle prowadzona. Na dodatek stworzona
                strona nie oddawała w żaden sposób walorów planowanego projektu,
                jednak ustaliliśmy wspólnie z klientem, że wykonany testowy
                miesiąc kampanii aby zweryfikować czy poprawa samej kampani
                wpłynie na realizację zamierzonych celów. Niestety miesiąc
                testowy potwierdził nasze podejrzenia. Strona miała prezentować
                klimat Japonii, tymczasem jedyną rzeczą która nawiązywała do
                Japonii było nieczytelne Logo oraz wiśnia na wizualizacjach
                dostarczonych przez dewelopera. Kolory niebieski oraz róż,
                kompletnie nie były dopasowane do klimatu projektu.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid kw51_section_3">
        <div className="row">
          <div className="col-md-8">
            <div className="inner">
              <h3>Projekt logotypu</h3>
              <p>
                Podeszliśmy do projektu całkowicie od nowa. Projektując nowe
                logo skupiliśmy się na prostocie oraz możliwości użycia logo na
                budynku wkomponowując japońską czcionkę w miejską identyfikację
                dotyczącą oznaczeń krakowskich budynków.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="conteiner-fluid kw51_section_4">
        <div className="row">
          <div className="col-md-2 offset-md-2">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/kw51_old_logo.png")}
              alt=""
            />
            <p>Poprzednie logo</p>
          </div>
          <div className="col-md-4 offset-md-3">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/kw51_new_logo.png")}
              alt=""
            />
            <p>Nowe logo</p>
          </div>
        </div>
      </section>
      <section className="container-fluid kw51_section_5">
        <div className="row">
          <div className="col-md-6 col_1">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/kw51_logo_belt.png")}
              alt=""
            />
            <div className="img_wrapper">
              <img
                className="img-fluid"
                src={require("../../assets/img/portfolio/kw51_new_logo.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-4 offset-md-2 col_2">
            <h3>Logo</h3>
            <div className="row">
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>Black CMYK</p>
                    <h5>
                      <span>0</span>
                      <span>0</span>
                      <span>0</span>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 100 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>White CMYK</p>
                    <h5>
                      <CountUp start={0} duration={3} end={isVisible ? 0 : 0} />
                      <CountUp start={0} duration={3} end={isVisible ? 0 : 0} />
                      <CountUp start={0} duration={3} end={isVisible ? 0 : 0} />
                      <CountUp start={0} duration={3} end={isVisible ? 0 : 0} />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <div className="col-sm-6">
                <p>RGB</p>
                <h5>
                  <span>0</span>
                  <span>0</span>
                  <span>0</span>
                </h5>
              </div>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>RGB</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 255 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 255 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 255 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>000000</h5>
              </div>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>ffffff</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid kw51_section_6">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="scroll_wrapper">
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/kw51_fullpage.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid kw51_section_7">
        <div className="row">
          <div className="col-md-5 col_1">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/kw51_img_1.png")}
              alt=""
            />
            <div className="inner offset-md-2">
              <p>
                jak prezentowały się wartości mieszkań na przestrzeni lat,
                dlaczego Krowodrza jest interesująca inwestycyjnie oraz dane
                dotyczące ruchu turystycznego w Krakowie. Opisaliśmy standard,
                design oraz charakter obiektu. Stworzyliśmy całkowicie na nowo
                system prezentacji mieszkań oraz pokazaliśmy dlaczego warto
                zaufać deweloperowi. Strona zyskała na przejrzystości oraz
                zaczęła budować pozytywne doświadczenia wśród użytkowników, co
                obserwowaliśmy na nagraniach z sesji odwiedzających stronę
                użytkowników.
              </p>
            </div>
          </div>

          <div className="col-md-5 col_2 offset-md-1">
            <div className="inner">
              <h3>Strona internetowa</h3>
              <p>
                Strona internetowa została przebudowana nie tylko w aspekcie
                wizualnym. Opracowaliśmy całkowicie nową strategię komunikacji
                inwestycji. Bardzo mocno rozbudowaliśmy zakładkę lokalizacja
                pokazując wszystkie kluczowe dla tej inwestycji aspekty.
                Dodatkowo stworzyliśmy zakładkę kierowaną do Inwestorów.
                Pokazaliśmy dlaczego warto inwestować właśnie w Krakowie,
              </p>
            </div>
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/kw51_img_2.png")}
              alt=""
            />
          </div>
        </div>
      </section>
      <section className="container-fluid kw51_section_8">
        <div className="row">
          <div className="col-md-7">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/kw51_img_3.png")}
              alt=""
            />
          </div>
          <div className="col-md-4 ">
            <div className="inner">
              <h3>Kampania reklamowa</h3>
              <p>
                Na potrzeby realizacji projektu opracowaliśmy unikalny Key
                Visual towarzyszący projektowi we wszystkich materiałach
                graficznych.
                <br />
                <br />
                Dla nowej strony internetowej uruchomiliśmy kampanię skierowaną
                do inwestorów oraz klientów poszukujących mieszkania. Mieszkanie
                nie należą do najtańszych, ponieważ oscylujemy w przedziale
                11300 - 13900 zł za m2, dlatego nasza kampania ukierunkowana
                była głównie do klientów, którzy kupowali mieszkania
                inwestycyjnie. Sytuacja w Polsce na przestrzeni 02.2020 -
                12.2020 deweloperowi nie sprzyjała, jednak pozyskane kontakty na
                nowej stronie przełożyły się na częściowe rezerwacje, pozyskanie
                zainteresowanych oraz budowę bazy inwestorów co kompletnie nie
                zdawało egzaminu na poprzedniej jej wersji.
                <br />
                <br />
                Na bazie prowadzonych w trakcie pandemii kampanii
                zaobserwowaliśmy, że w tym czasie najlepiej sprzedawały się
                mieszkania między 6000 a 8000 zł za m2 oraz domy z ogrodem pod
                Krakowem. Mieszkania w Centrum z uwagi na ogromne tąpnięcie na
                rynku turystycznym nie cieszyły się tak dużym zainteresowaniem
                jak przed lutym 2020 kiedy inwestorów interesowało głównie ROI a
                nie cena za m2 ponieważ Booking oraz AirBnB napędzały klientów.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid kw51_section_9">
        <div className="row">
          <div className="col-md-5 offset-md-1">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/kw51_img_4.png")}
              alt=""
            />
          </div>
          <div className="col-md-5 col_3 offset-md-1">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/kw51_img_5.png")}
              alt=""
            />
          </div>
        </div>
      </section>
      <section className="conteiner-fluid kw51_section_10">
        <div className="row">
          <div className="col-md-10">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/kw51_img_6.png")}
              alt=""
            />
          </div>
        </div>
      </section>
      <section className="container-fluid kw51_section_11">
        <img
          className="img-fluid"
          src={require("../../assets/img/portfolio/kw51_img_7.png")}
          alt=""
        />
      </section>
      <section className="container-fluid kw51_section_12">
        <div className="row">
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/kw51_img_8.png")}
              alt=""
            />
          </div>
          <div className="col-md-4 offset-md-1">
            <div className="inner">
              <h3>Reklamy zewnętrzne</h3>
              <p>
                Aby wesprzeć biuro sprzedaży przygotowaliśmy folder reklamowy
                skupiający najważniejsze informacje o projekcie.
                <br />
                <br />
                Dodatkowo przygotowaliśmy szereg reklam outdoorowych, które mają
                za zadanie informować o budowie oraz odesłać na stronę
                internetową.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid kw51_section_13">
        <div className="row">
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/kw51_img_9.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <BanerMDD />
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default KW51;
